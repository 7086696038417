.layout {
  width: 100%;
  min-width: 1440px;
  min-height: 100%;
  position: relative;

  .contentWrapper {
    box-sizing: border-box;

    &.isEmpty {
      padding-top: 0;
    }
  }
}
