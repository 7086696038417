.carSpecBasicInformation {
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
  flex-wrap: wrap;
  display: flex;
  padding-bottom: 0;

  .field {
    width: 50%;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    margin-bottom: 12px;

    .label {
      color: #98a3ad;
      white-space: nowrap;
      font-size: 12px;
      margin-bottom: 6px;
    }

    .value {
      line-height: 1.5;
      position: relative;
      top: -4px;
    }

    &.full {
      width: 100%;
    }
  }

  .closeButton {
    position: absolute;
    right: 24px;
    top: 24px;
    background: none;
    border: none;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.carSpec {
  position: relative;
  width: 100%;
  height: 100vh;

  .header {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: solid #efefef 1px;
    box-sizing: border-box;
    position: relative;

    .informations {
      display: flex;
    }

    .actions {
      display: flex;
      justify-content: space-between;
      width: 100%;
      box-sizing: border-box;
      padding: 0 24px;
      padding-bottom: 24px;

      .filterInput {
        display: flex;
        width: 250px;
        align-items: center;
        height: 48px;
        border-radius: 100px;
        transition: width 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
        border: solid #efefef 1px;

        svg {
          width: 32px;
          font-size: 18px;
        }

        input {
          height: 100%;
          border: none;
          background: none;
          font-size: 14px;
          box-sizing: border-box;
        }
      }

      .changedOnlyButton {
        background: #fff;
        position: relative;
        border-radius: 50%;
        width: 48px;
        height: 48px;
        z-index: 99;
        border: solid #efefef 1px;
        margin-left: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s ease-in-out opacity;
      }
    }

    &.isComparison {
      .carSpecBasicInformation {
        width: 50%;

        &:first-child {
          border-right: solid #efefef 1px;
        }
      }
    }

    .plus {
      display: flex;
      width: 48px;
      height: 48px;
      border-radius: 100px;
      transition: width 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
      border: solid #efefef 1px;
      overflow: hidden;

      button {
        position: relative;
        border: none;
        font-size: 20px;
        border-radius: 100px;
        width: 48px;
        min-width: 48px;
        height: 48px;
        background: none;
        vertical-align: middle;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        svg {
          transition: transform 0.3s ease-in-out;
        }
      }

      input {
        display: none;
        font-size: 14px;
        border: none;
        background: none;

        &::placeholder {
          color: #dadfe1;
        }
      }

      &.expand {
        width: 250px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);

        button {
          svg {
            transform: rotate(45deg);
          }
        }

        input {
          display: flex;
        }
      }
    }
  }

  .thead {
    th {
      font-size: 14px;
      text-align: left;
    }
    .no {
      padding: 8px 20px;
    }
    .category,
    .groupCode,
    .groupName,
    .code,
    .name {
      padding: 8px 10px;
    }

    .category {
      padding-left: 30px;
    }
  }

  .carSpecDetails {
    position: relative;
    box-sizing: border-box;
    display: flex;

    table {
      position: relative;
      border-collapse: collapse;
    }

    &.isComparison {
      table {
        width: 50% !important;
      }
    }
  }
}
