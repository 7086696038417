.button {
  -webkit-appearance: none;
  appearance: none;
  font-size: 14px;
  box-sizing: border-box;
  align-items: center;
  padding: 14px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  transition: background 0.15s cubic-bezier(0.4, 0, 0.2, 1), transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);

  overflow: hidden;

  &.primary {
    background: #396eff;
    color: #fff;

    &:hover {
      background: darken(#396eff, 10%);
    }

    &:active {
      background: darken(#396eff, 20%);
    }
  }

  &.danger {
    background: #fa5252;
    color: #fff;

    &:hover {
      background: darken(#fa5252, 10%);
    }

    &:active {
      background: darken(#fa5252, 20%);
    }
  }

  .loading {
    position: absolute;
    inset: 0;

    display: grid;

    place-items: center;

    background-color: inherit;
  }
}
