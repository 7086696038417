.search {
  display: flex;
  height: calc(100vh - 60px);
  .content {
    width: calc(100% - 350px);
    display: flex;
    flex-direction: column;
  }

  .side {
    display: flex;
    flex-direction: column;
  }
}
