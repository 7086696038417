.toastItem {
  color: #fff;
  display: flex;
  white-space: nowrap;
  position: fixed;
  align-items: center;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 4px;
  height: 40px;
  box-sizing: border-box;
  bottom: 40px;
  right: 40px;
  z-index: 9999;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  justify-content:center;

  .icon {
    display: flex;
    width: 30px;
    height: 30px;
    font-size: 18px;
    justify-content:center;
    align-items: center;
  }

  &.info {
    background: #228be6;
  }

  &.success {
    background: #40c057;
  }

  &.error {
    background: #fa5252;
  }
}
