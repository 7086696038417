.wrapperStyle {
  display: grid;
  gap: 16px;

  .form {
    padding: 30px;

    display: flex;
    gap: 16px;
  }

  hr {
    all: unset;
    height: 1px;
    width: 100%;
    background-color: #e0e0e0;
  }

  .content {
    padding: 30px;
    display: grid;
    gap: 16px;
    .status {
      text-align: center;
    }

    .items {
      display: grid;
      gap: 8px;
      .item {
        display: grid;
        gap: 16px;
        grid-template-columns: 100px auto;

        .label {
          color: #869ab7;
        }
      }
    }

    .rawDataButton {
      all: unset;
      display: block;
      position: relative;
      text-decoration: underline;
      font-size: 12px;
      cursor: pointer;
    }

    .modal {
      max-height: 80vh;
      max-width: 80vw;
      overflow: auto;
      padding: 20px;
      border-radius: 8px;
      font-size: 12px;
      line-height: 1.4;
    }
  }
}
