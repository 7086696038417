@import '../../styles/variables.module';

.vehicleRegisterError {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px;
  color: $red;
  .message {
    margin-bottom: 20px;
  }
}
