.radioGroups {
  display: flex;

  .radio {
    display: flex;
    cursor: pointer;
    font-size: 12px;
    align-items: center;
    margin-right: 30px;

    .shape {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 5px;
      box-shadow: inset 0 0 0 1px #dee2e6, 0 1px 2px 0 rgba(0, 0, 0, 0.05);
      position: relative;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: #396eff;
        opacity: 0;
        transform: scale(0);
        transition: opacity 0.15s ease, transform 0.15s ease;
      }
    }

    &.isSelected {
      .shape {
        box-shadow: inset 0 0 0 1px #396eff, 0 1px 2px 0 rgba(0, 0, 0, 0.05);

        &::after {
          opacity: 1;
          transform: scale(0.6);
        }
      }
    }
  }
}
