.commentForm {
  height: 200px;
  min-height: 200px;
  border-top: solid #ddd 1px;
  display: flex;
  box-sizing: border-box;
  padding: 20px;

  .textarea {
    width: 100%;
    margin-right: 20px;
    resize: none;
    border: solid #ddd 1px;
    border-radius: 2px;
    padding: 20px;
    font-size: 14px;
    box-sizing: border-box;

    &:focus {
      border-color: #396eff;
    }
  }

  .button {
    position: relative;
    width: 200px;
    border-radius: 2px;
    color: #fff;
    background: #396eff;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
}
