.carAccidents {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  .label {
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #869ab7;
    display: flex;
    margin-bottom: 8px;
  }

  .value {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    border: 1px solid #dadfe1;
    text-align: center;
    font-size: 12px;
    color: #425363;
    white-space: pre-wrap;
    line-height: 1.6;

    .item {
      display: flex;
      border-bottom: solid #ddd 1px;
      padding: 4px 0;

      &:last-child {
        border-bottom: none;
      }

      .head {
        display: flex;
        justify-content: space-between;
        width: 40%;

        .insuranceMoney {
          text-align: left;
          min-width: 60px;
        }
      }

      .component,
      .painting,
      .wage {
        display: flex;
        width: 20%;
        color: #98a3ad;
      }

      .head,
      .component,
      .painting {
        border-right: solid #ddd 1px;
      }

      .head,
      .component,
      .painting,
      .wage {
        padding: 0 10px;
        box-sizing: border-box;
        white-space: nowrap;
      }
    }
  }

  &.isEmpty {
    margin: 0;
    flex-direction: row;

    .label {
      min-width: 120px;
    }

    .value {
      width: auto;
      border: none;
      background: none;

      .empty {
        color: #dbe4ef;
        font-size: 14px;
      }
    }
  }
}
