$white: #ffffff;
$sky: #00b0ff;
$red: #ff5d5d;
$yellow: #ffbd5a;
$green: #50e3c2;
$blue: #396eff;
$gray: #9ba2ac;
$hidden: #98a3ad;
$disable: #c8d1d9;
$dark: #272e40;
$light-gray: #e7eaec;
$purple: #ba7bf1;
