.carSpecDetail {
  position: relative;
  font-size: 12px;
  background: #ffffff;

  td {
    text-align: left;
  }

  .symbol {
    position: absolute;
    font-weight: bold;
    line-height: 24px;
    left: 10px;
  }

  .no {
    border-left: 1px solid #f6f8fa;
    box-sizing: border-box;
    line-height: 24px;
    color: rgba(27, 31, 35, 0.3);
    text-align: left;
    position: relative;
    padding: 0 20px;
  }

  .category,
  .groupCode,
  .groupName,
  .code,
  .name {
    color: #24292e;
    line-height: 24px;
    box-sizing: border-box;
    text-align: left !important;
    white-space: nowrap;
    padding: 0 10px;
  }

  .category {
    padding-left: 30px;
  }

  .name {
    .word {
      border-radius: 0.2em;

      &.change {
        background: #acf2bd;
      }
    }
  }

  .hidden {
    color: #ddd;
  }

  &.insert {
    background: #e6ffed;

    .no {
      background-color: #cdffd8;
      border-color: #bef5cb;
    }
  }

  &.isEmpty {
    background: #fafafa;

    .empty {
      height: 24px;
    }
  }
}
