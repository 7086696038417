.carTag {
  display: flex;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 2px;
  background: #ff728d;
  color: #fff;
  border-right: 1px solid rgba(0,0,0,.1);
  box-sizing: border-box;
  font-size: 12px;

  .name {
    border-right: solid rgba(0, 0, 0, .1) 1px;
  }

  .name, .value {
    padding: 4px;
  }
}
