@import 'src/styles/variables.module';

.check {
  display: flex;
  height: 20px;
  margin-right: 30px;
  align-items: center;
  cursor: pointer;

  input {
    display: none;
  }

  .shape {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 4px;
    box-sizing: border-box;
    background: #ffffff;
    position: relative;
    display: flex;
    appearance: none;
    border: 1px solid rgba(67, 90, 111, 0.3);
    transition: box-shadow 0.15s ease-in-out;

    .icon {
      color: white;
      font-size: 16px;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      width: 16px;
      height: 16px;
      transform: scale(0);
      opacity: 0;
      transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
    }
  }
  .label {
    white-space: nowrap;
    font-size: 12px;
    font-weight: normal;
    user-select: none;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #343a40;
    transition: color 0.15s ease-in-out;
  }

  &.isOver {
    .shape {
      border: 1px solid #396eff;
    }
  }
  &.isChecked {

    .shape {
      background: #396eff;
      border: 1px solid #396eff;
    }

    .label {
      font-size: 12px;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #343a40;
    }

    .icon {
      transform: scale(1);
      opacity: 1;
    }
  }

  &.isDisabled {
    .shape {
      opacity: 0.5;
    }

    .label {
      color: #e9edf4;
    }
  }
}
