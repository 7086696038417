@import '../../styles/variables.module';

.header {
  width: 100%;
  background: #396eff;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-left: 20px;
  z-index: 99;
  justify-content: space-between;

  .navWrapper {
    display: flex;
    align-items: center;
    height: 60px;
    .logo {
      display: flex;
      background: url(../../images/logo.png) no-repeat center;
      background-size: 100%;
      width: 175px;
      height: 100%;
    }
    .nav {
      display: flex;
      font-size: 14px;
      font-weight: bold;
      .navLink {
        color: rgba($hidden, 0.8);
        margin-right: 16px;
        transition: color 0.15s ease-in-out;
        &:hover {
          color: $hidden;
        }
      }
      .active {
        color: white;
        &:hover {
          color: white;
        }
      }
    }
  }

  .logout {
    margin-right: 10px;
    box-shadow: none;
  }
}
