@import '../../styles/variables.module';

.rawDataPopUp {
  max-height: 80vh;
  max-width: 80vw;
  overflow: auto;
  padding: 20px;
  border-radius: 8px;
  font-size: 12px;
  line-height: 1.4;
}

.string {
  color: $dark;
}
.number {
  color: darkorange;
}
.boolean {
  color: $blue;
}
.null {
  color: $purple;
}
.key {
  color: $red;
}
