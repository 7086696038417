.vehicleIdentification {
  padding: 20px;
  width: calc(50% - 200px);
  overflow: auto;

  .options {
    display: flex;
    flex-direction: column;
    border: solid #ddd 1px;
    padding: 12px;
    border-radius: 4px;

    .option {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
      color: #396eff;
      font-size: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      .name {
        padding-right: 50px;
      }
    }
  }

  .vin {
    width: 24px;
    height: 24px;
    padding: 0;
    cursor: pointer;
    margin-left: 2px;
    border: 1px solid #e9edf4;
    background-color: #fff;
    font-size: 12px;
    line-height: 1;
    color: #869ab7;
    transition: box-shadow .3s ease-in-out;
    border-radius: 2px;

    &:hover {
      box-shadow: 0 2px 6px rgba(0,0,0,.1);
    }
  }
}
