.carRecordItem {
  box-sizing: border-box;
  margin-bottom: 5px;
  border-radius: 2px;
  background-color: #f5f5f5;
  border: 1px solid #dadfe1;
  text-align: center;
  font-size: 14px;
  color: #425363;
  white-space: pre-wrap;
  line-height: 1.6;
  padding: 4px 0;

  &:last-child {
    margin-bottom: 0;
  }
}
