@import 'src/styles/variables.module';

.searchForm {
  display: flex;
  height: 100px;
  padding: 30px;
  border-bottom: solid #ddd 1px;

  .searchTypes {
    padding-top: 10px;
    margin-bottom: 20px;
    display: flex;
    .border {
      border-left: 1px solid $light-gray;
      margin-right: 30px;
    }
  }

  .searchBar {
    display: flex;

    .input {
      height: 40px;

      & > div {
        width: 400px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .submit {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      left: -1px;
      height: 40px;
      padding: 0 20px;
    }
  }
}
