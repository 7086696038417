
.dimmer {
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background-color: white;
  min-width: 100px;
  min-height: 100px;
}
