.historySearchBar {
  background: #ffffff;
  border-bottom: solid #ddd 1px;

  .searchBar {
    display: flex;
    padding: 10px;

    .input {
      height: 40px;

      & > div {
        width: 280px;
        min-width: auto;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .submit {
      width: 50px;
      white-space: nowrap;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      left: -1px;
      height: 40px;
      padding: 0 20px;
    }
  }
}
