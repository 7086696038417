.field {
  display: flex;
  margin-bottom: 12px;

  .label {
    width: 120px;
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #869ab7;
    display: flex;
  }

  .value {
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #272e40;
    white-space: nowrap;
  }

  &.isNo {
    .value {
      color: #dbe4ef;
    }
  }
}
