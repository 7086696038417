.carRecord {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  overflow: auto;

  .header {
    display: flex;
    font-size: 14px;
    margin-bottom: 10px;
    justify-content: space-between;

    .label {
      color: #98a3ad;
    }
  }
}
