@import '../../styles/variables.module';

.vehicleRegister {
  padding: 20px;
  display: flex;
  .carInfo {
    display: flex;
    padding: 18px;
    margin-right: 80px;
    .column {
      margin-right: 40px;
      .section {
        margin-bottom: 40px;
      }
      .vin {
        width: 24px;
        height: 24px;
        padding: 0;
        cursor: pointer;
        margin-left: 2px;
        border: 1px solid #e9edf4;
        background-color: #fff;
        font-size: 12px;
        line-height: 1;
        color: #869ab7;
        transition: box-shadow 0.3s ease-in-out;
        border-radius: 2px;

        &:hover {
          box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
        }
      }
      .rawDataButton {
        position: relative;
        text-decoration: underline;
        color: $blue;
        font-size: 12px;
        cursor: pointer;
      }
    }
  }

  .recordContainer {
    height: calc(100vh - 261px);
    overflow: auto;
    width: 30%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
}
