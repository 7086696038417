.loginForm {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  .form {
    width: 300px;
    display: flex;
    flex-direction: column;

    .input {
      margin-bottom: 10px;
    }

    .actions {
      display: flex;

      .loginButton {
        width: 100%;
      }
    }
  }
}
