.histories {
  height: 100%;
  background: #fafafa;
  width: 350px;
  border-left: solid #ddd 1px;
  box-sizing: border-box;

  .items {
    height: calc(100% - 61px);
    overflow: auto;

    .columns {
      width: 100%;
      border: none;
      background: none;
      position: relative;
      display: flex;
      border-bottom: solid #ddd 1px;
      flex-direction: column;
      cursor: pointer;

      .first {
        display: flex;
      }

      .comment {
        padding: 10px;
        display: flex;
        flex-direction: column;
        padding-top: 0;
      }

      .column {
        width: 90px;
        padding: 10px;
        display: flex;
        flex-direction: column;

        .label,
        .value {
          text-align: left;
          display: block;
          font-size: 12px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .label {
          color: #98a3ad;
          margin-bottom: 2.5px;
        }
      }
    }
  }

  .readMore {
    width: 100%;
    height: 50px;
    background: none;
    border: none;
    font-size: 14px;
  }
}
