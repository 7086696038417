@import '../../styles/variables.module';

.record {
  padding: 18px;
  border: solid #ddd 1px;
  font-size: 14px;
  .titleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .title {
      font-weight: bold;
    }
    .numberWrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .number {
        color: $hidden;
      }
      .date {
        color: $hidden;
        margin-top: 6px;
        font-size: 12px;
        font-style: italic;
      }
    }

  }
  .content {
    white-space: pre-line;
    line-height: 1.51;
  }
}
.hasSubNumber {
  background-color: rgba($yellow, 0.5);
}
