.vehicleRegisterForm {
  display: flex;
  align-items: center;
  height: 100px;
  padding: 30px;
  border-bottom: solid #ddd 1px;

  .inputWrapper {
    display: flex;
    margin-right: 32px;
    .input {
      width: 160px;
      min-width: auto;
      margin-right: 8px;
    }
  }
  .radioGroupWrapper {
    margin-right: 10px;
    .row {
      display: flex;
      margin-bottom: 8px;
    }
  }
}
